.richtext-editor-styles {
  h1, h2, h4 {
    font-family: 'Domine', serif;
    font-weight: 700;
  }

  h3, h5, h6 {
    font-family: 'Source Sans Pro', sans-serif;
  }

  h1 {
    font-size: 1.5rem;
    line-height: 150%;
    margin-top: .625rem;
    @media (min-width: 640px) {
      margin-top: 1.875rem;
      font-size: 3rem;
      line-height: 140%;
    }
  }

  h2 {
    font-size: 1.125rem;
    line-height: 140%;
    margin-top: .625rem;
    @media (min-width: 640px) {
      margin-top: 1.875rem;
      font-size: 2.25rem;
      line-height: 130%;
    }
  }

  h3 {
    font-size: 1.25rem;
    line-height: 150%;
    font-weight: 400;
    margin-top: .625rem;
    @media (min-width: 640px) {
      margin-top: 1.875rem;
      font-size: 2rem;
    }
  }

  h4 {
    font-size: 1rem;
    line-height: 150%;
    margin-top: .625rem;
    @media (min-width: 640px) {
      margin-top: 1.875rem;
      font-size: 1.5rem;
    }
  }

  h5 {
    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
    margin-top: .625rem;
    @media (min-width: 640px) {
      margin-top: 1.875rem;
      font-size: 1.5rem;
    }
  }

  h6 {
    font-weight: 400;
    font-size: .875rem;
    line-height: 150%;
    margin-top: .625rem;
    @media (min-width: 640px) {
      margin-top: 1.875rem;
      font-size: 1.25rem;
    }
  }

  ul {
    margin: 1rem 0;

    li {
      padding-left: 25px;
      position: relative;

      &:before {
        content: '';
        width: .5rem;
        height: .5rem;
        position: absolute;
        border-radius: 100%;
        background: #AF8232;
        left: 0;
        top: .75rem;
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: item;
    margin: 1rem 0;

    li {
      counter-increment: item;
      position: relative;
      padding-left: 30px;
      margin-bottom: 5px;

      &:before {
        content: counter(item);
        margin-right: 5px;
        font-size: 70%;
        background-color: #AF8232;
        color: white;
        font-weight: bold;
        padding: 0 8px;
        border-radius: 3px;
        position: absolute;
        top: 0;
        left: 0;
      }

      p {
        display: inline-block;
      }
    }
  }

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  a {
    color: #017FCB;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin-bottom: .3125rem;
    @media (min-width: 640px) {
      margin-bottom: 1.25rem;
    }
  }

  blockquote {
    display: block;
    height: auto;
    margin: 20px 0 !important;
    padding: 5px 0 0 40px;
    border-left: 1px solid #af8232;
  }

  blockquote::before {
    content: url('https://a.storyblok.com/f/148087/x/6079b847a9/vector.svg');
    display: block;
    width: 26px;
    height: 20px;
    margin: 10px 5px 10px 0;
  }


}


table {
  border: 1px solid #ddd;
  border-collapse: separate !important;
  border-left: 0;
  border-radius: 8px;
  border-spacing: 0;
  margin: 1rem;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

th, td {
  padding: 15px;
  text-align: left;
  vertical-align: top;
}

@media (max-width: 639px) {
  th, td {
    padding: 0px;
  }
}

th, td {
  &:first-child {
    border-left: 1px solid #ddd;

  }
}

th {
  background: #eee;
  color: #7e7e7e;
}

td {
  border-top: 1px solid #ddd;
}

thead:first-child tr:first-child th:first-child, tbody:first-child tr:first-child td:first-child {
  border-radius: 8px 0 0 0;
}

thead:last-child tr:last-child th:first-child, tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 8px;
}
