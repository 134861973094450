.swiperSlideWrapper {
  // display: flex;
  // flex-direction: column;
  padding-bottom: 1.5rem !important;
  .swiper-slide-duplicate-next > div {
    background-color: #fff !important;
    color: black !important;
  }
}

.swiperSlide {
  height: auto;
  &-active {
    background-color: #bd1701 !important;
  }
  > div {
    width: 374px !important;
    min-height: 480px !important;
    @media (max-width: 639px) {
      width: 300px !important;
      min-height: 480px !important;
    }
  }
}

.swiperSlide :global(.swiper-slide-next) {
  color: purple !important;
}

.swiperButtonNext {
  right: 10px !important;
  top: calc(50% - (var(--swiper-navigation-size) / 2)) !important;
  left: auto;
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 1rem;

  svg {
    color: white !important;
  }
}

.swiperButtonPrev {
  left: 10px !important;
  top: calc(50% - (var(--swiper-navigation-size) / 2)) !important;
  right: auto;
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 1rem;

  svg {
    color: white !important;
  }
}

.swiperPaginationCustom {
  margin: 3rem auto 0;
  width: max-content !important;
  position: relative !important;
  background-color: #111827;
  left: auto !important;
  bottom: auto !important;
  span {
    background-color: white !important;
    margin: 0 8px !important;
  }
}

.swiperButtonNext::after,
.swiperButtonPrev::after {
  content: '' !important;
}

@media (max-width: 960px) {
  .swiperButtonNext {
    right: calc(50% - 270.5px) !important;
    left: auto;
  }
  .swiperButtonPrev {
    left: calc(50% - 270.5px) !important;
    right: auto;
  }
}

@media (max-width: 640px) {
  .swiperButtonNext {
    right: 16px !important;
    left: auto;
  }

  .swiperButtonPrev {
    left: 16px !important;
    right: auto;
  }
}

@media (max-width: 321px) {
  .swiperSlideWrapper {
    margin-bottom: 20px;
  }

  .swiperPaginationCustom {
    width: 100% !important;
    bottom: 0px !important;
  }
}
