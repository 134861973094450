/**
 * Tailwind CSS
 */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'Domine';
  src: url('fonts/Domine/Domine-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Domine Medium';
  src: url('fonts/Domine/Domine-Medium.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Domine Semi Bold';
  src: url('fonts/Domine/Domine-SemiBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Domine Bold';
  src: url('fonts/Domine/Domine-Bold.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('fonts/Source-Sans-Pro/SourceSansPro-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro Bold';
  src: url('fonts/Source-Sans-Pro/SourceSansPro-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('fonts/Inter/Inter-Regular.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Inter Medium';
  src: url('fonts/Inter/Inter-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Semi Bold';
  src: url('fonts/Inter/Inter-SemiBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Bold';
  src: url('fonts/Inter/Inter-Bold.woff2') format('woff2');
  font-display: swap;
}
html {
  scroll-behavior: auto !important;
}

/* Custom Tailwind CSS Sytle */
.underline--wave {
  /* https://yoksel.github.io/url-encoder/ viewBox='0 0 439 15' */
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 439 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 12C76.0617 -8.63707 391.946 20.9917 436 3' stroke='%23AF8232' stroke-width='6' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E%0A");
  background-position: 0 20px;
  background-size: 100% 100%;
  min-width: fit-content;
  display: inline;
  padding-bottom: 7px;
  padding-top: 0;
  background-repeat: no-repeat;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@keyframes scale {
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
}

.leaflet-bottom.leaflet-right {
  display: none;
}

.shepherd-modal-overlay-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.5;
  z-index: 40;
}

.shepherd-cancel-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 24px;
  font-size: 50px;
  color: #3f3f46;
}

.shepherd-footer {
  display: flex;
  flex-direction: row;
  margin: auto;
  column-gap: 20px;
}

#stepFinal-description + .shepherd-footer {
  flex-direction: column;
  row-gap: 10px;
}
