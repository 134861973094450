.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background:theme('colors.coolGray.100');
}

.scrollbar::-webkit-scrollbar-thumb {
  background:theme('colors.coolGray.300');
  border-radius: 100vh;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background:theme('colors.coolGray.300');
}
