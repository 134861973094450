.gaugeInnerText{
  left: 50%;
  position: absolute;
  text-align: center;
  top: 57%;
  transform: translate(-50%, -50%);
  z-index: 1;
  min-width: 150px;
  height: 130px;
}

.gaugeCenter{
  top: 50%;
}
