.postsPagination {
  width: max-content;
  margin: 50px auto 0px;
  display: flex;
  flex-direction: row;
  border: 1px solid #d1d5db;
  border-radius: 3px;
  li {
    padding: 7px 15px;
    color: #6b7280;
    border-right: 1px solid #d1d5db;
    &:last-of-type {
      border-right: none;
    }
  }
}

.paginationActive {
  color: #3b82f6 !important;
}
