.swiperSlide {
  padding-bottom: 20px !important;
}

.swiperButtonNext {
  left: auto;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
}

.swiperButtonPrev {
  right: auto;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
}

.swiperPaginationCustom {
  position: relative !important;
  width: fit-content !important;
  margin: auto;
  left: unset !important;
  bottom: unset !important;
}

.swiperButtonNext::after,
.swiperButtonPrev::after {
  content: '' !important;
}

@media (max-width: 640px) {
  .swiperButtonNext {
    left: auto;
  }
  .swiperButtonPrev {
    right: auto;
  }
}

@media (max-width: 321px) {
  .swiperSlide > div {
    width: 192px !important;
    margin-bottom: 20px;
  }
  .swiperButtonNext {
    left: auto;
    z-index: 99;
    display: none !important;
  }
  .swiperButtonPrev {
    right: auto;
    z-index: 99;
    display: none !important;
  }
}

.swiperSlide:nth-child(2n) {
  margin-top: 3rem;
}

@media (max-width: 834px) {
  .swiperSlide:nth-child(2n) {
    margin-top: 0 !important;
  }
}
.swiperSlideWrapperCentered > div {
  align-items: center;
}
